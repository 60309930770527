import Constants from 'src/assets/constants';
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
import Maintenance from "../components/GeneralViews/Maintenance.vue";
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "dashboard" */ 'src/components/Dashboard/Views/Dashboard/OverviewNew.vue');
const OverviewBroker = () => import(/* webpackChunkName: "dashboard" */ 'src/components/Dashboard/Views/Dashboard/OverviewBroker.vue');
const OverviewDashboard = () => import(/* webpackChunkName: "dashboard" */ 'src/components/Dashboard/Views/Dashboard/overview/Overview.vue');
const Profile = () => import(/* webpackChunkName: "dashboard" */ 'src/components/Dashboard/Views/Dashboard/UserProfile/Profile.vue');
// Accounts Pages
const AllAccounts = () => import(/* webpackChunkName: "accounts" */ 'src/components/Dashboard/Views/Dashboard/Accounts/AllAccounts.vue');
const FollowersAdmin = () => import(/* webpackChunkName: "accounts" */ '@/components/Dashboard/Views/Dashboard/Accounts/FollowerAdmin/FollowersAdmin.vue');
const MyAccounts = () => import(/* webpackChunkName: "accounts" */ 'src/components/Dashboard/Views/Dashboard/Accounts/MyAccounts.vue');
const StrategyParameters = () => import(/* webpackChunkName: "accounts" */ "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameters.vue");
const OpenAccount = () => import(/* webpackChunkName: "accounts" */ 'src/components/Dashboard/Views/Dashboard/Accounts/OpenAccount.vue');
// const Proptrading = () => import(/* webpackChunkName: "accounts" */ 'src/components/Dashboard/Views/Dashboard/Accounts/Proptrading.vue');
const AccountDeets = () => import(/* webpackChunkName: "accounts" */ 'src/components/Dashboard/Views/Dashboard/Accounts/AccountDetail.vue');
const MultiAddAcc = () => import(/* webpackChunkName: "accounts" */ 'src/components/Dashboard/Views/Dashboard/Accounts/MultipleAddAccount.vue');
// Affiliate Pages
const AfflShare = () => import(/* webpackChunkName: "affiliate" */ 'src/components/Dashboard/Views/Dashboard/Affiliate/Share.vue');
const AfflStats = () => import(/* webpackChunkName: "affiliate" */ 'src/components/Dashboard/Views/Dashboard/Affiliate/Stats.vue');
const Followers = () => import(/* webpackChunkName: "affiliate" */ 'src/components/Dashboard/Views/Dashboard/Affiliate/Followers.vue');
const Banner = () => import(/* webpackChunkName: "affiliate" */ '@/components/Dashboard/Views/Dashboard/Affiliate/Banners.vue');
// Strategy Dashboard Pages
const AllStrategies_v2 = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/AllStrategies.v2.vue');
const BrokerStrategies = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/BrokerStrategies.vue');
const BrokerAccounts = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/BrokerAccounts.vue');
const MATs = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/MATs.vue');
const MATDetail = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/MATDetail.vue');
const MATsTable = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/MATsTable.vue')
const SetFiles = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/SetfilesV2.vue')
const AddStrategy = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/AddStrategy.vue');
const DetailStrategy = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/StrategyDetail.vue');
const MarketStrategy = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Marketplace/Marketplace.vue');
const StrategyInfo = () => import(/* webpackChunkName: "strategy" */ 'src/components/Dashboard/Views/Dashboard/Strategies/StrategyInfo.vue');
const BuyVPS = () => import(/* webpackChunkName: "vps" */ 'src/components/Dashboard/Views/Dashboard/VPS/BuyVPS.vue');
// VPS Dashboard Pages
const VpsList = () => import(/* webpackChunkName: "vps" */ 'src/components/Dashboard/Views/Dashboard/VPS/VpsList.vue');
const RequestVPS = () => import(/* webpackChunkName: "vps" */ 'src/components/Dashboard/Views/Dashboard/VPS/RequestVPS.vue');
const WebRDP = () => import(/* webpackChunkName: "vps" */ 'src/components/Dashboard/Views/Dashboard/VPS/WebRDP.vue');
// Banner Pages
const MyBanners = () => import(/* webpackChunkName: "banner" */ 'src/components/Dashboard/Views/Dashboard/Banner/MyBanners.vue');
const NewBanner = () => import(/* webpackChunkName: "banner" */ 'src/components/Dashboard/Views/Dashboard/Banner/NewBanner.vue');
// Billing Pages
const Billing = () => import(/* webpackChunkName: "billing" */ 'src/components/Dashboard/Views/Dashboard/Billing/Billing.vue');
const BillingWallet = () => import(/* webpackChunkName: "billing" */ 'src/components/Dashboard/Views/Dashboard/Billing/BillingWallet.vue');
const UserManager = () => import('src/components/Dashboard/Views/Dashboard/UserProfile/UserManager.vue');
const InOut = () => import(/* webpackChunkName: "billing" */ 'src/components/Dashboard/Views/Dashboard/Billing/InOut.v2.vue');
const Transfer = () => import(/* webpackChunkName: "billing" */ 'src/components/Dashboard/Views/Dashboard/Billing/Transfer.vue');
const Funding = () => import(/* webpackChunkName: "billing" */ 'src/components/Dashboard/Views/Dashboard/Billing/Funding.vue');
const LedgerMarketplaceLegacy = () => import(/* webpackChunkName: "billing" */ '@/components/Dashboard/Views/Dashboard/Billing/LedgerMarketplaceLegacy.vue');
const LedgerMarketplaceV2 = () => import(/* webpackChunkName: "billing" */ '@/components/Dashboard/Views/Dashboard/Billing/LedgerMarketplace.v2.vue');
// Support Pages
const RedmineTicket = () => import(/* webpackChunkName: "support" */ 'src/components/Dashboard/Views/Dashboard/Support/RedmineTicket.vue');
const ServerManagement = () => import(/* webpackChunkName: "support" */ 'src/components/Dashboard/Views/Dashboard/Support/ServerManagement.vue');
const CreateTicket = () => import(/* webpackChunkName: "support" */ 'src/components/Dashboard/Views/Dashboard/Support/CreateTicket.vue');
const FAQ = () => import(/* webpackChunkName: "support" */ 'src/components/Dashboard/Views/Dashboard/Support/FAQ.vue');
const Policies = () => import(/* webpackChunkName: "support" */ 'src/components/Dashboard/Views/Dashboard/Support/Policies.vue');
const Explanation = () => import('src/components/Dashboard/Views/Dashboard/Support/Explanation.vue');
// Change Logs Pages
const NewMasters = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/NewMasters.vue');
const NewPlatforms = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/NewPlatforms.vue');
const Params = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/Params.vue');
const SyncTrades = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/SyncTrades.vue');
const Deletes = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/Deletes.vue');
const Disconnects = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/Disconnects.vue');
const MatRelocation = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/MatRelocation.vue');
const Validations = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/Validations.vue');
const ReValidations = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/ReValidations.vue');
const WithdrawalsChangeLog = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/ChangeLogs/Withdrawals.vue');
// wallet
const WalletOrderManagement = () => import(/* webpackChunkName: "changelog" */ 'src/components/Dashboard/Views/Dashboard/Wallet/OrderManagement');
// whitelabel
const WhitelabelCrud = () => import('src/components/Dashboard/Views/Dashboard/Whitelabel_v2/WhitelabelCrud');
const WhitelabelForm = () => import('src/components/Dashboard/Views/Dashboard/Whitelabel_v2/WhitelabelForm');
const WhitelabelPlanCrud = () => import('src/components/Dashboard/Views/Dashboard/Whitelabel_v2/WhitelabelPlanCrud');

// Messenger
const Messenger = () => import(/* webpackChunkName: "messenger" */ "src/components/Dashboard/Views/Dashboard/Messenger/Messenger.vue");

const BrokerPerformance = () => import("../components/Dashboard/Views/Dashboard/Billing/BrokerPerformance.vue");
// Pages
import Login from 'src/components/Dashboard/Views/Pages/Login.vue';
import LoginOld from 'src/components/Dashboard/Views/Pages/Login-old.vue';
import AuthCB from 'src/components/Dashboard/Views/Pages/AuthCB.vue';
import Register from 'src/components/Dashboard/Views/Pages/Register.vue';
import RegisterWL from 'src/components/Dashboard/Views/Pages/RegisterWL.vue';
import EmailVerify from 'src/components/Dashboard/Views/Pages/EmailVerify.vue';
import ResetPassword from 'src/components/Dashboard/Views/Pages/ResetPassword.vue';
import PublicStrategy from 'src/components/Dashboard/Views/Pages/PublicStrategy.vue';
import WhitelabelAdmin from 'src/components/Dashboard/Views/Dashboard/Whitelabel/WhitelabelAdmin.vue';
import WhitelabelManage from 'src/components/Dashboard/Views/Dashboard/Whitelabel/WhitelabelManage.vue';
import WhitelabelVps from 'src/components/Dashboard/Views/Dashboard/Whitelabel/WhitelabelVps.vue';
import BlackList from "@/components/Dashboard/Views/Dashboard/Strategies/BlackList";
import NewServerIni from "../components/Dashboard/Views/Dashboard/ChangeLogs/NewServerIni.vue";
import PublicRemoveUser from "@/components/Dashboard/Views/Pages/PublicRemoveUser.vue";
import PerformanceCredit from "@/components/Dashboard/Views/Dashboard/Billing/Broker/PerformanceCredit.vue";
import PerformanceDebit from "@/components/Dashboard/Views/Dashboard/Billing/Broker/PerformanceDebit.vue";
import BrokerPerformanceConfirmation from "../components/Dashboard/Views/Dashboard/Billing/BrokerPerformanceConfirmation.vue";

/**
 * Method defined to get all needed settings
 * for sidebarlinks and main-routes mounting
 */
const rules = Constants.methods.getRules();

/**
 * Array with all routes
 */
let routes = [
  // DEFAULT
  {
    show: true,
    path: '*',
    component: NotFound
  },
  {
    show: true,
    path: '/maintenance',
    component: Maintenance
  },
  {
    show: true,
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    show: true,
    path: '/login-old',
    name: 'LoginOld',
    component: LoginOld
  },
  {
    show: true,
    path: '/remove',
    name: 'remove',
    component: PublicRemoveUser
  },
  {
    show: true,
    path: '/:wl/register',
    component: RegisterWL,
    redirect: '/:wl/register/main',
    children: [
      {
        show: true,
        path: 'main',
        name: 'RegisterMainWL',
        component: RegisterWL
      },
      {
        show: true,
        path: ':afl_id',
        name: 'WlRegisterWAFLID',
        component: RegisterWL
      },
      {
        show: true,
        path: ':afl_id/:strat_id',
        name: 'WlRegisterAffiliateStrategy',
        component: Register
      }
    ]
  },
  {
    show: true,
    path: '/register',
    component: Register,
    redirect: '/register/main',
    children: [
      {
        show: true,
        path: 'main',
        name: 'RegisterMain',
        component: Register
      },
      {
        show: true,
        path: ':afl_id',
        name: 'RegisterAFLID',
        component: Register
      },
      {
        show: true,
        path: ':afl_id/:strat_id',
        name: 'RegisterAffiliateStrategy',
        component: Register
      }
    ]
  },
  {
    show: true,
    path: '/email_verified/:userid/:token',
    name: 'Verify Email',
    component: EmailVerify
  },
  {
    show: true,
    path: '/reset_password/:userid/:token',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    show: true,
    path: '/webrdp/:rdp',
    name: 'WebRDP',
    component: WebRDP
  },
  {
    show: true,
    path: '/authcallback',
    name: 'AuthCallback',
    component: AuthCB
  },
  {
    show: true,
    path: '/public/:surl',
    name: 'Public Strategy',
    component: PublicStrategy
  },
  {
    show: true,
    path: '/public/:surl/:wl',
    name: 'Public Strategy WL',
    component: PublicStrategy
  },
  {
    show: true,
    path: '/:wl',
    name: 'wlLanding',
    component: Login
  },
  {
    show: true,
    path: '/support',
    component: DashboardLayout,
    name: 'Dashboard Initial',
    redirect: '/support/main',
    children: [
      {
        show: rules.check('/support/main'),
        path: 'main',
        name: 'FAQ',
        component: FAQ,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/yNWXWty7ScI'],
          tutorialPt: ['https://youtu.be/Hc-2v_zKK2M'],
          tutorialAr: ['https://youtu.be/hwZnmifTXjk'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=10dj-K2lXno8KVJ5PJyWcu1AiggCNTZt9'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1w17MFPHWVDQasegye7WaOQJKQ5MpAdPb&export=download'],
        }
      },
      {
        show: true,
        path: 'explanation',
        name: 'pricing',
        component: Explanation,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/Y-X2GbHUb3E'],
          tutorialPt: ['https://youtu.be/Hc-2v_zKK2M'],
          tutorialAr: ['https://youtu.be/hwZnmifTXjk'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=10dj-K2lXno8KVJ5PJyWcu1AiggCNTZt9'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1w17MFPHWVDQasegye7WaOQJKQ5MpAdPb&export=download'],
        }
      },
      {
        show: true,
        path: 'helpdesk',
        name: 'Need Help',
        component: CreateTicket,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/Y-X2GbHUb3E'],
          tutorialPt: ['https://youtu.be/Hc-2v_zKK2M'],
          tutorialAr: ['https://youtu.be/hwZnmifTXjk'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=10dj-K2lXno8KVJ5PJyWcu1AiggCNTZt9'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1w17MFPHWVDQasegye7WaOQJKQ5MpAdPb&export=download'],
        }
      },
      {
        show: true,
        path: 'policies',
        name: 'Policies',
        component: Policies,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/Y-X2GbHUb3E'],
          tutorialPt: ['https://youtu.be/Hc-2v_zKK2M'],
          tutorialAr: ['https://youtu.be/hwZnmifTXjk'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=10dj-K2lXno8KVJ5PJyWcu1AiggCNTZt9'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1w17MFPHWVDQasegye7WaOQJKQ5MpAdPb&export=download'],
        }
      },
      {
        show: rules.isAdmin  || rules.isMarketing,
        role: ['admin'],
        path: 'messenger',
        name: 'Messenger',
        component: Messenger,
        meta: { requiresAuth: true }
      },
      {
        show: rules.isAdmin,
        role: ['admin'],
        path: 'servers',
        name: 'servertxt',
        component: ServerManagement,
        meta: { requiresAuth: true }
      },
      {
        show: true,
        path: '/users/manager',
        name: 'User Manager',
        component: UserManager,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: rules.isAdmin|| rules.isSupport,
        role: ['admin'],
        path: 'tickets',
        name: 'tickettxt',
        component: RedmineTicket,
        meta: { requiresAuth: true }
      },
    ]
  },
  // REGULAR
  {
    path: '/',
    show: !rules.isBroker && !rules.isAdmin && !rules.isSupport,
    role: ['user'],
    component: DashboardLayout,
    redirect: rules.getFirstPage(),
    meta: { requiresAuth: true, role: 'user' }
  },                   // DASH
  {
    path: '/dash',
    show: !rules.isBroker && !rules.isAdmin && !rules.isSupport,
    role: ['user'],
    component: DashboardLayout,
    redirect: '/dash/main',
    children: [
      {
        show: !rules.isBroker && !rules.isAdmin && !rules.isSupport && rules.first_page == 'default',
        path: 'main',
        name: 'Overview',
        component: Overview,
        meta: { requiresAuth: true, role: 'user' }
      },
      {
        show: true,
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
      },
    ]
  },               // DASH
  {
    path: '/accounts',
    show: !rules.isBroker && !rules.isAdmin && !rules.isSupport,
    role: ['user'],
    component: DashboardLayout,
    redirect: '/accounts/main',
    children: [
      {
        show: !rules.isBroker && !rules.isAdmin && !rules.isSupport && rules.check('/accounts/main'),
        path: 'main',
        name: 'MyAccounts',
        component: MyAccounts,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/S0-RerbBofI', 'https://youtu.be/mCNtu8_7QLc'],
          tutorialPt: ['https://youtu.be/qwWUo3iTFKg', 'https://youtu.be/GrQ3Aah1jp4'],
          tutorialAr: ['https://youtu.be/ne-bRlsOvZ0','https://youtu.be/Co-Kc6-gq5I'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1IlwhUj6fy2PppX0dulkkwS5bxM5jJ7Dv', 'https://drive.google.com/uc?export=download&id=10bpvTfu1ecu-0FY_toQFEQvzOjV_PHos'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1VDNNf_1yFByqAWNsRPPsSYp_fR7w-bc5&export=download', 'https://drive.google.com/u/0/uc?id=1ZMs8167o2VOTD4t9gdF9PEsi1ZF88g1C&export=download'],
        }
      },
      {
        show: true,
        path: 'bulkadd',
        name: 'BulkAdd',
        component: MultiAddAcc,
        meta: { requiresAuth: true }
      },
      {
        show: !rules.isBroker && !rules.isAdmin && !rules.isSupport && rules.check('/accounts/open'),
        path: 'open',
        name: 'OpenAccount',
        component: OpenAccount,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/-hH6wKE8Pmg'],
          tutorialPt: ['https://youtu.be/rJQgrI6v4Kk?si=0Uf3V_X5BgVHFAsh'],
          tutorialAr: ['https://youtu.be/OAcWN3SNg4k'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1REQGZXJH956qjke4LtBfnNUUp8Kr7X9L'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1BedDutrEmHHl1SZ6Fa-Jx8JCo7lHZXGX&export=download'],
        }
      },/*{
        show: !rules.isBroker && !rules.isAdmin && rules.check('/accounts/open'),
        path: 'proptrading',
        name: 'proptrading',
        component: Proptrading,
        meta: { requiresAuth: true }
      },*/
      {
        show: true,
        path: ':userid/:accid',
        name: 'Account Details',
        component: AccountDeets,
        meta: { requiresAuth: true }
      },
      {
        show: true,
        path: 'parameters/:accid/:stratid',
        name: 'Parameters',
        component: StrategyParameters,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/MSb8Qp1ZACg'],
          tutorialPt: ['https://youtu.be/wOq9ROJ-Wn8?si=LYI8bmlkLsIK3sqw'],
          tutorialAr: ['https://youtu.be/mhyI79Sz_iE'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1yA7vpGT5LC0R3TRpJ2CFo6jGDLqaO95u'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1gsjdRViYWRHGRkHzm55ERljQWBms0qtS&export=download'],
        }
      },
    ]
  }, // ACCOUNTS
  {
    path: '/strategies',
    show: !rules.isAdmin && !rules.isSupport,
    role: ['user'],
    component: DashboardLayout,
    redirect: '/strategies/watchlist',
    children: [
      {
        show: rules.check('/strategies/watchlist') && !rules.isAdmin && !rules.isSupport,
        path: 'watchlist',
        name: 'watchlist',
        component: MarketStrategy,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/Z8BbKd9IwzI'],
          tutorialPt: ['https://youtu.be/eykDBySlQgE'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1Yybf6Vf0jUFSz59cUPVn5F4BSzuMfY10&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1bH0bW4NzUrzdAeA5ve0F31gtKdVa3PWO&export=download'],
        }
      },
      {
        show: rules.check('/strategies/market') && !rules.isAdmin && !rules.isSupport,
        path: 'market',
        name: 'Marketplace',
        component: MarketStrategy,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/Z8BbKd9IwzI'],
          tutorialPt: ['https://youtu.be/Z7PRe43-R4I?si=aUmG3gLsvNYuMQ3h'],
          tutorialAr: ['https://youtu.be/-J27OU1OZDY'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1nNun2rEW3D8M58B_PiH6PuLZPbR-7eG4&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1Mf8-pBawNBz8o5UBUKU1--p3SFn4jzYa&export=download'],
        }
      },
      {
        show: !rules.isBroker,
        path: 'add',
        name: 'AddStrategy',
        component: AddStrategy,
        meta: { requiresAuth: true,
          tutorialPt: ['https://youtu.be/GrQ3Aah1jp4?si=9_XBhRwdsBe9dDpT'],
          tutorialEn: ['https://youtu.be/mCNtu8_7QLc'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=12qDmdL1jSZLlr9HpTf7PAuZ9f8AznIQc&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1ZMs8167o2VOTD4t9gdF9PEsi1ZF88g1C&export=download'],
          }
      },
      {
        show: !rules.isBroker,
        path: 'add/:aid',
        name: 'AddStrategyId',
        component: DetailStrategy,
        meta: { requiresAuth: true,
          tutorialPt: ['https://youtu.be/GrQ3Aah1jp4?si=9_XBhRwdsBe9dDpT'],
          tutorialEn: ['https://youtu.be/mCNtu8_7QLc'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=12qDmdL1jSZLlr9HpTf7PAuZ9f8AznIQc&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1ZMs8167o2VOTD4t9gdF9PEsi1ZF88g1C&export=download'],
        }
      },
      {
        show: true,
        path: 'info',
        name: 'InfoStrategy',
        component: StrategyInfo,
        meta: { requiresAuth: true }
      },
      {
        show: !rules.isBroker,
        path: ':aid/:sid',
        name: 'StrategyDetail',
        component: DetailStrategy,
        meta: { requiresAuth: true }
      },
    ]
  },         // STRATEGIES
  {
    path: '/billing',
    show: !rules.isBroker,
    role: ['user'],
    component: DashboardLayout,
    redirect: '/billing/funding',
    children: [
      {
        show: rules.check('/billing/funding') && !rules.isSupport,
        path: 'funding',
        name: 'Funding',
        component: Funding,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/bitTjOLbd9k'],
          tutorialPt: ['https://youtu.be/cmypcryE4YM'],
          tutorialAr: ['https://youtu.be/QL8FdzLoBk4'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=13iFsvsd47k6F_DCNfxQ5NuiSfsWpmAkb&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1PR5oXCuCKNej4_debNf-4KVkCC4aiNRi&export=download'],
        }
      },
      // {
      //   show: rules.check('/billing/withdrawals'),
      //   path: 'withdrawals',
      //   name: 'withdrawals',
      //   component: Withdrawals,
      //   meta: {
      //     requiresAuth: true,
      //     tutorialEn: ['https://youtu.be/0XKw0Qzp_Do'],
      //     tutorialPt: ['https://youtu.be/t-fLdjRMXAQ'],
      //     tutorialAr: ['https://youtu.be/7rRfbk75Trw'],
      //     pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1ZPd9Q3kaYOgQe_kv0pJ9sLo8843eQ6i7&export=download'],
      //     pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
      //   }
      // },
      {
        show: rules.check('/billing/inout') && !rules.isSupport,
        path: 'inout',
        name: 'InOut',
        component: InOut,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/hgTxT_RLbVI'],
          tutorialPt: ['https://youtu.be/dtxyvIv3xZE'],
          tutorialAr: ['https://youtu.be/WwozfiL3du0'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1opUU1mJqfcgGs149dwwIQIFm5pp7wSt7&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
        }
      },
      {
        show: !rules.removeIfUserHas(['Neteller']) && rules.check('/billing/transfer') && !rules.isSupport,
        path: 'transfer',
        name: 'Transfer',
        component: Transfer,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/HrJUib0KJk8'],
          tutorialPt: ['https://youtu.be/xrfQYBTPg10'],
          tutorialAr: ['https://youtu.be/ym5Lu7p04KU'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1GeJ405CkjkygLW8C-_65AZwcdf2bJ8qx&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1czfx9_v0VOAEmd4OMeSp6FP493ysrf7Z&export=download'],
        }
      },
      {
        show: rules.check('/billing/orders') && !rules.isSupport,
        path: 'orders',
        name: 'wallet_order_title',
        component: WalletOrderManagement,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/eUepFrhMAuc?si=H7ciJnlg3M-FoXBj'],
          tutorialPt: ['https://youtu.be/PrT9xosFubQ'],
          tutorialAr: ['https://youtu.be/7rRfbk75Trw'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1ZPd9Q3kaYOgQe_kv0pJ9sLo8843eQ6i7&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
        }
      },
      {
        show: rules.check('/billing/marketplace'),
        path: 'marketplace',
        name: 'LedgerMarketplace',
        component: LedgerMarketplaceV2,
        meta: { requiresAuth: true,
          tutorialEn: ['https://youtu.be/Jc7yHLsZzhk'],
          tutorialPt: ['https://youtu.be/sTVZVTuv_Ek'],
          tutorialAr: ['https://youtu.be/tX39_PYB5Qg'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1rB5zFSdEsrSAmgZFBRot6Wi5mgRhmIc2&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1vvrZPZlqt42yImbQTiTGj8TiMFm-cvOQ&export=download'],
        }
      },
    ]
  },            // BILLING
  {
    path: '/affiliate',
    show: rules.isAdmin
      || rules.isSupport
      || rules.check('/affiliate')
      || rules.check('/affiliate/payouts')
      || rules.check('/affiliate/followers')
      || rules.check('/affiliate/banner'),
    role: ['user'],
    component: DashboardLayout,
    redirect: '/affiliate/share',
    children: [
      {
        show: rules.check('/affiliate'),
        path: 'share',
        name: 'Share',
        component: AfflShare,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/j7w5SX7rje0'],
          tutorialPt: ['https://youtu.be/JcCvZI-JfCc?si=b1LjsmSxp6l8jvVj'],
          tutorialAr: ['https://youtu.be/ziRCcI_4bEA'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1y-J5xKvyK2qeM4TCKdGGdGp7gQgQI6ug'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1xDKHnfCW3v3rv_FKo0gdxLioW_QuxReb&export=download'],
        }
      },
      {
        show: rules.check('/affiliate'),
        path: 'stats',
        name: 'Stats',
        component: AfflStats,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/j7w5SX7rje0'],
          tutorialPt: ['https://youtu.be/JcCvZI-JfCc?si=b1LjsmSxp6l8jvVj'],
          tutorialAr: ['https://youtu.be/ziRCcI_4bEA'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1y-J5xKvyK2qeM4TCKdGGdGp7gQgQI6ug'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1xDKHnfCW3v3rv_FKo0gdxLioW_QuxReb&export=download'],
        }
      },
      {
        show: true,
        path: 'banner',
        name: 'banner',
        component: Banner,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/lfEzuPvkKRM'],
          tutorialPt: ['https://youtu.be/jVS2qauUydk'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=11DqnoaRlwGMOKQupWCe8ALeM_nI0QinA'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1qtxZ70ZgABAmNbEIpfADA6KcbC9Ycw5K&export=download'],
        }
      },
      {
        show: rules.isAdmin || rules.isSupport,
        path: ':userid/stats',
        name: 'Stats-adm',
        component: AfflStats,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/j7w5SX7rje0'],
          tutorialPt: ['https://youtu.be/JcCvZI-JfCc'],
          tutorialAr: ['https://youtu.be/ziRCcI_4bEA'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1y-J5xKvyK2qeM4TCKdGGdGp7gQgQI6ug'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1xDKHnfCW3v3rv_FKo0gdxLioW_QuxReb&export=download'],
        }
      },
      {
        show: rules.check('/affiliate/followers'),
        path: 'followers',
        name: 'UserFollowers',
        component: Followers,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/KvqT0wps-lo'],
          tutorialPt: ['https://youtu.be/-2WrrEXl5zM'],
          tutorialAr: ['https://youtu.be/oU9gOET64Zg'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=17Ff_haKrPOTArhLHqOC8LMGGRHrKOwTl'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1mMxlhzimuYhLrnv_ZgBU7UGgmT949q48&export=download'],
        }
      },
      {
        show: rules.isAdmin || rules.isSupport,
        path: ':userid/followers',
        name: 'UserFollowers-adm',
        component: Followers,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/KvqT0wps-lo'],
          tutorialPt: ['https://youtu.be/-2WrrEXl5zM'],
          tutorialAr: ['https://youtu.be/oU9gOET64Zg'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=17Ff_haKrPOTArhLHqOC8LMGGRHrKOwTl'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1mMxlhzimuYhLrnv_ZgBU7UGgmT949q48&export=download'],
        }
      }
    ]
  },          // AFFILIATE
  // BROKER
  {
    path: '/',
    show: rules.isBroker,
    role: ['broker'],
    component: DashboardLayout,
    redirect: '/dash/main',
    meta: { requiresAuth: true, role: 'broker' }
  },                   // DASH
  {
    path: '/dash',
    show: rules.isBroker,
    role: ['broker'],
    component: DashboardLayout,
    redirect: '/dash/main',
    children: [
      {
        show: true,
        path: 'main',
        name: 'Overview',
        component: OverviewBroker,
        meta: { requiresAuth: true, role: 'broker' }
      },
      {
        show: true,
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
      }
    ]
  },               // DASH
  {
    path: '/accounts',
    show: rules.isBroker,
    role: ['broker'],
    component: DashboardLayout,
    redirect: '/accounts/all',
    children: [
      {
        show: true,
        path: 'all',
        name: 'All Accounts',
        component: BrokerAccounts,
        meta: { requiresAuth: true, role: 'broker' }
      },
      {
        show: true,
        path: ':userid',
        name: 'User Account',
        component: MyAccounts,
        meta: {
          requiresAuth: true,
          role: 'broker',
          tutorialEn: ['https://youtu.be/S0-RerbBofI', 'https://youtu.be/mCNtu8_7QLc'],
          tutorialPt: ['https://youtu.be/qwWUo3iTFKg', 'https://youtu.be/GrQ3Aah1jp4'],
          tutorialAr: ['https://youtu.be/ne-bRlsOvZ0', 'https://youtu.be/Co-Kc6-gq5I'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1IlwhUj6fy2PppX0dulkkwS5bxM5jJ7Dv', 'https://drive.google.com/uc?export=download&id=10bpvTfu1ecu-0FY_toQFEQvzOjV_PHos'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1VDNNf_1yFByqAWNsRPPsSYp_fR7w-bc5&export=download', 'https://drive.google.com/u/0/uc?id=1ZMs8167o2VOTD4t9gdF9PEsi1ZF88g1C&export=download'],
        }
      },
      {
        show: true,
        path: ':userid/:accid',
        name: 'Account Details',
        component: AccountDeets,
        meta: { requiresAuth: true, role: 'broker' }
      }
    ]
  },           // ACCOUNTS
  {
    path: '/strategies',
    show: rules.isBroker,
    role: ['broker'],
    component: DashboardLayout,
    redirect: '/strategies/all',
    children: [
      {
        show: true,
        path: 'all',
        name: 'Broker Strategies',
        component: BrokerStrategies,
        meta: { requiresAuth: true, role: 'broker' }
      }
    ]
  },         // STRATEGIES
  {
    path: '/billing',
    show: rules.isBroker,
    role: ['broker'],
    component: DashboardLayout,
    redirect: '/billing/funding',
    children: [
      {
        show: true,
        path: 'funding',
        name: 'Funding',
        component: Funding,
        meta: {
          requiresAuth: true,
          role: 'broker',
          tutorialEn: ['https://youtu.be/bitTjOLbd9k'],
          tutorialPt: ['https://youtu.be/cmypcryE4YM'],
          tutorialAr: ['https://youtu.be/QL8FdzLoBk4'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=13iFsvsd47k6F_DCNfxQ5NuiSfsWpmAkb&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1PR5oXCuCKNej4_debNf-4KVkCC4aiNRi&export=download'],
        }
      },
      // {
      //   show: true,
      //   path: 'withdrawals',
      //   name: 'withdrawals',
      //   component: Withdrawals,
      //   meta: {
      //     requiresAuth: true,
      //     role: 'broker',
      //     tutorialEn: ['https://youtu.be/0XKw0Qzp_Do'],
      //     tutorialPt: ['https://youtu.be/t-fLdjRMXAQ'],
      //     tutorialAr: ['https://youtu.be/7rRfbk75Trw'],
      //     pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1ZPd9Q3kaYOgQe_kv0pJ9sLo8843eQ6i7&export=download'],
      //     pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
      //   }
      // },
      {
        show: true,
        path: 'inout',
        name: 'InOut',
        component: InOut,
        meta: {
          requiresAuth: true,
          role: 'broker',
          tutorialEn: ['https://youtu.be/hgTxT_RLbVI'],
          tutorialPt: ['https://youtu.be/dtxyvIv3xZE'],
          tutorialAr: ['https://youtu.be/WwozfiL3du0'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1opUU1mJqfcgGs149dwwIQIFm5pp7wSt7&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
        }
      },
      {
        show: true,
        path: 'transfer',
        name: 'Transfer',
        component: Transfer,
        meta: {
          requiresAuth: true,
          role: 'broker',
          tutorialEn: ['https://youtu.be/HrJUib0KJk8'],
          tutorialPt: ['https://youtu.be/xrfQYBTPg10'],
          tutorialAr: ['https://youtu.be/ym5Lu7p04KU'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1GeJ405CkjkygLW8C-_65AZwcdf2bJ8qx&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1czfx9_v0VOAEmd4OMeSp6FP493ysrf7Z&export=download'],
        }
      },
      {
        show: true,
        path: 'orders',
        name: 'wallet_order_title',
        component: WalletOrderManagement,
        meta: {
          requiresAuth: true,
          role: 'broker',
          tutorialEn: ['https://youtu.be/eUepFrhMAuc?si=H7ciJnlg3M-FoXBj'],
          tutorialPt: ['https://youtu.be/PrT9xosFubQ'],
          tutorialAr: ['https://youtu.be/7rRfbk75Trw'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1ZPd9Q3kaYOgQe_kv0pJ9sLo8843eQ6i7&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1CIajj-_UvMWXwzN4xFsfVE0rZA8r0Bya&export=download'],
        }
      },
    ]
  },            // BILLING
  // ADMIN
  {
    path: '/',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/dash/main',
  },                   // DASH
  {
    path: '/dash',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/dash/main',
    children: [
      {
        show: true,
        path: 'main',
        name: 'Overview',
        component: OverviewDashboard,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
      },
    ]
  },               // DASH

  {
    path: '/admin_accounts',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/admin_accounts/all',
    children: [
      {
        show: true,
        path: 'all',
        name: 'All Accounts',
        component: AllAccounts,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'followers',
        name: 'FollowersAdmin',
        component: FollowersAdmin,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: ':userid/bulkadd',
        name: 'MultipleAddAccount',
        component: MultiAddAcc,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'open',
        name: 'OpenAccount',
        component: OpenAccount,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/-hH6wKE8Pmg'],
          tutorialPt: ['https://youtu.be/rJQgrI6v4Kk'],
          tutorialAr: ['https://youtu.be/OAcWN3SNg4k'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1REQGZXJH956qjke4LtBfnNUUp8Kr7X9L'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1BedDutrEmHHl1SZ6Fa-Jx8JCo7lHZXGX&export=download'],
        }
      },
      {
        show: true,
        path: ':userid',
        name: 'User Account',
        component: MyAccounts,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/S0-RerbBofI', 'https://youtu.be/mCNtu8_7QLc'],
          tutorialPt: ['https://youtu.be/qwWUo3iTFKg', 'https://youtu.be/GrQ3Aah1jp4'],
          tutorialAr: ['https://youtu.be/ne-bRlsOvZ0','https://youtu.be/Co-Kc6-gq5I'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1IlwhUj6fy2PppX0dulkkwS5bxM5jJ7Dv', 'https://drive.google.com/uc?export=download&id=10bpvTfu1ecu-0FY_toQFEQvzOjV_PHos'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1VDNNf_1yFByqAWNsRPPsSYp_fR7w-bc5&export=download', 'https://drive.google.com/u/0/uc?id=1ZMs8167o2VOTD4t9gdF9PEsi1ZF88g1C&export=download'],
        }
      },
      {
        show: true,
        path: ':userid/:accid',
        name: 'Account History',
        component: AccountDeets,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        role: ['admin', 'support'],
        path: 'parameters/:accid/:stratid',
        name: 'Parameters',
        component: StrategyParameters,
        meta: {
          requiresAuth: true,
          tutorialEn: ['https://youtu.be/MSb8Qp1ZACg'],
          tutorialPt: ['https://youtu.be/wOq9ROJ-Wn8'],
          tutorialAr: ['https://youtu.be/mhyI79Sz_iE'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1yA7vpGT5LC0R3TRpJ2CFo6jGDLqaO95u'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1gsjdRViYWRHGRkHzm55ERljQWBms0qtS&export=download'],
        }
      }
    ]
  },     // ACCOUNTS
  {
    path: '/admin_strategies',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/admin_strategies/all',
    children: [
      {
        show: true,
        path: 'all',
        name: 'All Strategies',
        component: AllStrategies_v2,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'market',
        name: 'Marketplace',
        component: MarketStrategy,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/Z8BbKd9IwzI'],
          tutorialPt: ['https://youtu.be/Z7PRe43-R4I'],
          tutorialAr: ['https://youtu.be/-J27OU1OZDY'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1nNun2rEW3D8M58B_PiH6PuLZPbR-7eG4&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1Mf8-pBawNBz8o5UBUKU1--p3SFn4jzYa&export=download'],
        }
      },
      {
        show: true,
        path: 'mats',
        name: 'MATs',
        component: MATs,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'Migrate',
        name: 'MATsTable',
        component: MATsTable,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'setfiles',
        name: 'Setfiles',
        component: SetFiles,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'info',
        name: 'InfoStrategy',
        component: StrategyInfo,
        meta: { requiresAuth: true }
      },
      {
        show: true,
        path: 'mat/:mat_server',
        name: 'MATDetail',
        component: MATDetail,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: ':userid/add/:aid',
        name: 'Strategy Detail',
        component: DetailStrategy,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: ':userid/:aid/:sid',
        name: 'Strategy Detail',
        component: DetailStrategy,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: !rules.isSupport,
        path: 'blacklist',
        name: 'Blacklist',
        component: BlackList,
        meda: { requiresAuth: true, role: 'admin' }
      }
    ]
  },   // STRATEGIES
  {
    path: '/changes',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/changes/main',
    children: [
      {
        show: true,
        path: 'tradedassets',
        name: 'TradedAssets',
        component: NewMasters,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'platforms',
        name: 'New Platforms',
        component: NewPlatforms,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'params',
        name: 'Params Changes',
        component: Params,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'synctrades',
        name: 'changelogpendingsynctrades',
        component: SyncTrades,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'deletes',
        name: 'Deletes',
        component: Deletes,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'disconnects',
        name: 'Disconnects',
        component: Disconnects,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'matrelocation',
        name: 'MatRelocation',
        component: MatRelocation,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'validations',
        name: 'Validations',
        component: Validations,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'revalidations',
        name: 'ReValidations',
        component: ReValidations,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: !rules.isSupport,
        path: 'withdrawals',
        name: 'WithdrawalsChangeLog',
        component: WithdrawalsChangeLog,
        meta: { requiresAuth: true, role: 'admin' }
      }
    ]
  },            // CHANGELOGS
  {
    path: '/banner',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/banner/main',
    children: [
      {
        show: rules.isAdmin || rules.isSupport,
        path: 'main',
        name: 'MyBanners',
        component: MyBanners,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: rules.isAdmin || rules.isSupport,
        path: 'add',
        name: 'New Banner',
        component: NewBanner,
        meta: { requiresAuth: true, role: 'admin' }
      }
    ]
  },             // BANNER
  {
    path: '/billing',
    show: rules.isAdmin || rules.isSupport,
    role: ['admin', 'support'],
    component: DashboardLayout,
    redirect: '/billing/main',
    children: [
      {
        show: true,
        path: 'main',
        name: 'VPS Billing',
        component: Billing,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'wallet',
        name: 'Wallet Billing',
        component: BillingWallet,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'inout/:user_id',
        name: 'InOutUser',
        component: InOut,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/hgTxT_RLbVI'],
          tutorialPt: ['https://youtu.be/dtxyvIv3xZE'],
          tutorialAr: ['https://youtu.be/WwozfiL3du0'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1opUU1mJqfcgGs149dwwIQIFm5pp7wSt7&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
        }
      },
      {
        show: true,
        path: 'funding',
        name: 'Funding',
        component: Funding,
        meta: {
          requiresAuth: true,
          role: 'admin',
          tutorialEn: ['https://youtu.be/bitTjOLbd9k'],
          tutorialPt: ['https://youtu.be/cmypcryE4YM'],
          tutorialAr: ['https://youtu.be/QL8FdzLoBk4'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=13iFsvsd47k6F_DCNfxQ5NuiSfsWpmAkb&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1PR5oXCuCKNej4_debNf-4KVkCC4aiNRi&export=download'],
        }
      },
      // {
      //   show: true,
      //   path: 'withdrawals',
      //   name: 'Withdrawals',
      //   component: Withdrawals,
      //   meta: {
      //     requiresAuth: true,
      //     role: 'admin',
      //     tutorialEn: ['https://youtu.be/0XKw0Qzp_Do'],
      //     tutorialPt: ['https://youtu.be/t-fLdjRMXAQ'],
      //     tutorialAr: ['https://youtu.be/7rRfbk75Trw'],
      //     pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1ZPd9Q3kaYOgQe_kv0pJ9sLo8843eQ6i7&export=download'],
      //     pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1SL1eX9hKAHx__NZ8lLzDKyynXNzyW6yq&export=download'],
      //   }
      // },
      {
        show: true,
        path: 'orders',
        name: 'wallet_order_title',
        component: WalletOrderManagement,
        meta: {
          requiresAuth: true,
          role: ['admin', 'support'],
          tutorialEn: ['https://youtu.be/eUepFrhMAuc?si=H7ciJnlg3M-FoXBj'],
          tutorialPt: ['https://youtu.be/PrT9xosFubQ'],
          tutorialAr: ['https://youtu.be/7rRfbk75Trw'],
          pdftutorialEn: ['https://drive.google.com/u/0/uc?id=1ZPd9Q3kaYOgQe_kv0pJ9sLo8843eQ6i7&export=download'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1CIajj-_UvMWXwzN4xFsfVE0rZA8r0Bya&export=download'],
        }
      },
    ]
  },            // BILLING
  {
    path: '/performance',
    show: true,
    role: ['admin'],
    component: DashboardLayout,
    children: [
      {
        show: true,
        path: 'debit',
        name: 'Debit',
        component: PerformanceDebit,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'credit',
        name: 'Credit',
        component: PerformanceCredit,
        meta: { requiresAuth: true, role: 'admin' }
      },
      {
        show: true,
        path: 'broker',
        name: 'Broker Performance',
        component: BrokerPerformance,
        meta: {
          requiresAuth: true,
        }
      },
    ]
  },
  // USER, BROKER, ADMIN
  {
    show: true,
    role: ['user', 'broker', 'admin', 'support'],
    path: '/vps',
    component: DashboardLayout,
    redirect: '/vps/main',
    children: [
      {
        show: rules.check('/vps/buy') || rules.isAdmin || rules.isSupport || (rules.isWhitelabelAdmin && rules.isWhitelabelSession),
        path: 'buy',
        name: 'Buy VPS',
        component: BuyVPS,
        meta: {
          requiresAuth: true,
          role: 'user',
          tutorialEn: ['https://youtu.be/mRBP_H-AnDA'],
          tutorialPt: ['https://youtu.be/rHaH3YY6iAs?si=3re8uMh7CPbJUQnO'],
          tutorialAr: ['https://youtu.be/FXHOLcKziD4'],
          pdftutorialEn: ['https://drive.google.com/uc?export=download&id=1VBRk4E_ZHJdvSmNeW77a3JHn7kHnGTGm'],
          pdftutorialPt: ['https://drive.google.com/u/0/uc?id=1_Go-nQBlWLzFNc0gfAgujCqZegosLB3P&export=download'],
        }
      },
      {
        show: rules.check('/vps/request') || rules.isBroker || rules.isAdmin || rules.isSupport || rules.isWhitelabelAdmin,
        path: 'main',
        name: 'MyVPSs',
        component: VpsList,
        meta: {
          requiresAuth: true,
          role: 'user',
        }
      },
      {
        show: (rules.isWhitelabelSession && (rules.check('/vps/request') || rules.isWhitelabelAdmin)) || rules.isAdmin || rules.isSupport,
        path: 'request',
        name: 'Request VPS',
        component: RequestVPS,
        meta: { requiresAuth: true, role: 'user' }
      }
    ]
  },                // VPS
  // BROKER, ADMIN
  {
    path: '/wladmin',
    show: rules.isAdmin || rules.isWhitelabelAdmin,
    role: ['wladmin', 'admin'],
    component: DashboardLayout,
    redirect: rules.isWhitelabelAdmin ? '/wladmin/admin' : '/wladmin/manage',
    children: [
      {
        show: rules.isWhitelabelAdmin || rules.isAdmin,
        path: 'admin',
        name: 'Manage whitelabel settings',
        component: WhitelabelAdmin,
        meta: { requiresAuth: true }
      },
      {
        show: rules.isAdmin,
        path: 'manage',
        name: 'All Whitelabels',
        component: WhitelabelManage,
        meta: { requiresAuth: true }
      },
      {
        show: rules.isWhitelabelAdmin || rules.isAdmin,
        path: 'wlvps',
        name: 'Vps Settings for Whitelabel',
        component: WhitelabelVps,
        meta: { requiresAuth: true }
      },
      {
        show: rules.isAdmin,
        path: 'plans',
        name: 'whitelabelplans',
        component: WhitelabelPlanCrud,
        meta: { requiresAuth: true }
      },
      {
        show: rules.isAdmin,
        path: 'form',
        name: 'Whitelabel Form',
        component: WhitelabelForm,
        meta: { requiresAuth: true }
      },
      {
        show: rules.isAdmin,
        path: 'crud',
        name: 'Whitelabel crud',
        component: WhitelabelCrud,
        meta: { requiresAuth: true }
      }
    ]
  },            // WHITELABEL ADMIN
  // NO AUTHENTIATED
  {
    show: !rules.isAuthenticated,
    path: '/',
    component: Login,
    redirect: '/login'
  },

];

/**
 * Method defined to filter routes;
 * @param route
 * @returns {boolean|*}
 */
const filterRoutes = route => {
  if (route.children) {
    route.children = route.children.filter(child => child.show);
    if (!route.children.length) {
      return false;
    }
  }
  return route.show;
};

export default routes.filter(filterRoutes);
