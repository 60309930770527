<template>
  <div class="user">
    <div class="photo">
      <el-avatar :src="user.user_img"></el-avatar>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           {{ user.first_name }} {{ user.last_name }}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="/dash/profile">
                <span class="sidebar-mini-icon">
                  <i class="nc-icon nc-settings"></i>
                </span>
                <span class="sidebar-normal">{{ $t('edit_profile') }}</span>
              </a>
            </li>
            <li>
              <a href="#" v-on:click="logout">
                <span class="sidebar-mini-icon">
                  <i class="fa fa-sign-out"></i>
                </span>
                <span class="sidebar-normal">{{ $t('logout') }}</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>

  import Vue from 'vue';
  import { CollapseTransition } from 'vue2-transitions'
  import { Avatar } from 'element-ui'

  const user_info = localStorage.getItem('user-info');

  Vue.use(Avatar)

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        userPlaceholder: '/static/img/user_placeholder.png',
        avatarBaseUrl: "https://hoko.ams3.digitaloceanspaces.com/",
        isClosed: true,
        user: {
          first_name: '',
          last_name: '',
          user_img: ''
        }, admin: false,
        showBilling: false
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      logout() {
        this.auth.logout();
        this.auth.redirect();
      },
      loadUserInfo(u_info) {
        this.$getUserInfo(u_info['email']).then((resp) => {
          this.avatarPlaceholder(u_info, resp.data[0].show_avatar_placeholder)
        }, function(err) {
          console.log(err);
        });
      },
      avatarPlaceholder(u_info, show) {
        if (show) {
          this.user.user_img = this.userPlaceholder
        } else {
          this.user.user_img = u_info['picture']
        }
      }
    },
    mounted () {

      // User Info
      let u_info = JSON.parse(user_info);

      this.loadUserInfo(u_info)

      var user_metadata = u_info['user_metadata'];
      var app_metadata = u_info['app_metadata'];

      if (user_metadata !== undefined) {
        // Get User Info
        if (user_metadata['first_name'] !== undefined && user_metadata !== undefined) {
          this.user.first_name = user_metadata['first_name'];
          this.user.last_name = user_metadata['last_name'];
        }
        else {
          this.user.first_name = u_info['email'];
        }
      }
      // Admin?
      if (u_info['roles'].indexOf('admin') >= 0) {
        this.admin = true;
      }

      //console.log(app_metadata['roles'].indexOf('broker'));
      if (app_metadata !== undefined) {
        // Show Billing or not
        if (app_metadata['roles'].indexOf('admin') >= 0 && app_metadata['roles'].indexOf('broker') >= 0) {
          this.showBilling = true;
        }
        else {
          if (app_metadata['roles'].indexOf('admin') < 0 && app_metadata['roles'].indexOf('user') >= 0 && app_metadata['roles'].indexOf('broker') < 0) {
            this.showBilling = true;
          }
        }
      }

    },
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
