import Constants from 'src/assets/constants';

/**
 * Method defined to get all needed settings
 * for sidebarlinks and main-routes mounting
 */
const rules = Constants.methods.getRules();

/**
 * Sidebar links
 */
const sideMenuItems = [
  {
    show: rules.isAdmin || rules.isSupport || rules.isBroker || (rules.first_page == 'default'),
    roles: ['user', 'broker'],
    name: 'Dashboard',
    icon: 'nc-icon nc-layout-11',
    path: '/dash/main'
  },
  {
    show: rules.isWhitelabelAdmin || rules.isAdmin,
    roles: ['admin', 'wladmin'],
    name: 'Whitelabel',
    icon: 'fa fa-coffee',
    children: [
      {
        show: rules.isWhitelabelAdmin & !rules.isAdmin,
        roles: ['admin', 'wladmin'],
        name: 'whitelabeladmin',
        icon: 'fa fa-gears',
        path: '/wladmin/admin',
        modal: false
      },
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'whitelabelmanage',
        icon: 'fa fa-gear',
        path: '/wladmin/manage',
        modal: false
      },
      {
        show: rules.isWhitelabelAdmin & !rules.isAdmin,
        roles: ['admin'],
        name: 'vpsbillingplans',
        icon: 'fa fa-server',
        path: '/wladmin/wlvps',
        modal: false
      },
      // Whitelabel - V2
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'whitelabel_crud',
        icon: 'fa fa-coffee',
        path: '/wladmin/crud',
        modal: false
      },
      // {
      //   show: rules.isAdmin,
      //   roles: ['admin'],
      //   name: 'whitelabel_form',
      //   icon: 'fa fa-coffee',
      //   path: '/wladmin/form',
      //   modal: false
      // },
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'whitelabel_plans_crud',
        path: '/wladmin/plans',
        icon: 'fa fa-newspaper-o',
        modal: false
      },
    ]
  },
  {
    show: !rules.isBroker,
    roles: ['user','admin', 'support'],
    name: 'accountstxt',
    icon: 'fa fa-th',
    children: [
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'allaccounts',
        icon: 'fa fa-th-large',
        path: '/admin_accounts/all',
        modal: false
      },
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'partnerbrokerstxt',
        icon: 'fa fa-handshake-o',
        path: '/admin_accounts/open',
        modal: false
      },
      {
        show: !rules.isBroker && !rules.isAdmin && !rules.isSupport && rules.check('/accounts/main'),
        roles: ['user'],
        name: 'myaccounts',
        icon: 'fa fa-th-large',
        path: '/accounts/main',
        modal: false
      },
       {
        show: !rules.isAdmin && !rules.isBroker && !rules.isSupport && rules.check('/affiliate/followers'),
        name: 'followerstxt',
        icon: 'fa fa-users',
        path: '/affiliate/followers',
        modal: false
      },
      {
        show: !rules.isBroker && !rules.isAdmin && !rules.isSupport && rules.check('/accounts/open'),
        roles: ['user'],
        name: 'partnerbrokerstxt',
        icon: 'fa fa-handshake-o',
        path: '/accounts/open',
        modal: false
      },
      /*{
        show: !rules.isBroker && !rules.isAdmin && rules.check('/accounts/open') && !rules.isWhitelabelSession,
        roles: ['user'],
        name: 'PropTrading',
        icon: 'fa fa-trophy',
        path: '/accounts/proptrading',
        modal: false
      },*/
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'followers_admin',
        icon: 'fa fa-users',
        path: '/admin_accounts/followers',
        modal: false
      }
    ]
  },
  {
    show: rules.isBroker,
    roles: ['broker'],
    name: 'accountstxt',
    icon: 'fa fa-th',
    path: '/accounts/all'
  },
  {
    show: true,
    roles: ['user','admin', 'support'],
    name: 'Strategies',
    icon: 'fa fa-bar-chart',
    children: [
      {
        show: rules.check('/strategies/market') && !rules.isAdmin && !rules.isSupport,
        roles: ['user'],
        name: 'Marketplace',
        icon: 'fa fa-shopping-bag',
        path: '/strategies/market',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'All Strategies',
        icon: 'nc-icon nc-sound-wave',
        path: '/admin_strategies/all',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Marketplace',
        icon: 'fa fa-shopping-bag',
        path: '/admin_strategies/market',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'MATs',
        icon: 'fa fa-desktop',
        path: '/admin_strategies/mats',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Migrate',
        icon: 'fa fa-arrows-h',
        path: '/admin_strategies/Migrate',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Setfiles',
        icon: 'fa fa-refresh',
        path: '/admin_strategies/setfiles',
        modal: false
      },
      {
        show: rules.isBroker,
        roles: ['broker'],
        name: 'Strategies',
        icon: 'nc-icon nc-sound-wave',
        path: '/strategies/all'
      },
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'Blacklist',
        icon: 'fa fa-user-times',
        path: '/admin_strategies/blacklist',
        modal: false
      }
    ]
  },
  {
    show: rules.isAdmin || rules.isSupport,
    roles: ['admin', 'support'],
    name: 'Change Logs',
    icon: 'fa fa-table',
    children: [
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Deletes',
        icon: 'fa fa-trash-o',
        path: '/changes/deletes',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Disconnects',
        icon: 'fa fa-plug',
        path: '/changes/disconnects',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'matrelocation',
        icon: 'fa fa-retweet',
        path: '/changes/matrelocation',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'New Platforms',
        icon: 'nc-icon nc-laptop',
        path: '/changes/platforms',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Params Changes',
        icon: 'nc-icon nc-settings-gear-65',
        path: '/changes/params',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'changelogpendingsynctrades',
        icon: 'fa fa-compress',
        path: '/changes/synctrades',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'ReValidations',
        icon: 'fa fa-refresh',
        path: '/changes/revalidations',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'helper_tradedassets',
        icon: 'nc-icon nc-tv-2',
        path: '/changes/tradedassets',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'Validations',
        icon: 'fa fa-sign-in',
        path: '/changes/validations',
        modal: false
      },
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'Withdrawals',
        icon: 'fa fa-forward',
        path: '/changes/withdrawals',
        modal: false
      }
    ]
  },
  {
    show: rules.isAdmin || rules.isBroker,
    roles: ['admin','broker'],
    name: 'performance',
    icon: 'fa fa-credit-card-alt',
    children: [
      {
        show: rules.isAdmin || rules.isBroker,
        roles: ['admin','broker'],
        name: 'debittxt',
        icon: 'fa fa-arrow-down',
        path: '/performance/debit',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isBroker,
        roles: ['admin','broker'],
        name: 'credittxt',
        icon: 'fa fa-arrow-up',
        path: '/performance/credit',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isBroker,
        name: 'Performance (legacy)',
        icon: 'fa fa-usd',
        path: '/performance/broker',
        modal: false
      },
    ]
  },
  {
    show: rules.check('/vps/request') || rules.isBroker || rules.isAdmin || rules.isWhitelabelAdmin || rules.isSupport,
    name: 'VPS',
    icon: 'fa fa-server',
    children: [
      {
        show: rules.isBroker || rules.isAdmin || rules.isWhitelabelAdmin || rules.isSupport || rules.check('/vps/request'),
        name: rules.isAdmin || rules.isWhitelabelAdmin || rules.isSupport ? 'Active VPSs' : 'MyVPSs',
        icon: 'fa fa-cubes',
        path: '/vps/main',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport
          || (rules.isWhitelabelAdmin && rules.isWhitelabelSession && rules.check('/vps/buy'))
          || (!rules.isBroker && !rules.isWhitelabelSession && rules.check('/vps/buy') ),
        name: 'Buy VPS',
        icon: 'fa fa-plus',
        path: '/vps/buy',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport || (rules.isWhitelabelSession && rules.check('/vps/request')),
        name: 'requestwlvps',
        icon: 'fa fa-plus',
        path: '/vps/request',
        modal: false
      }
    ]
  },
  {
    show: Constants.methods.walletme(),
    name: 'Billing',
    icon: 'fa fa-money',
    children: [
      {
        show: rules.isAdmin,
        name: 'Wallet Billing',
        icon: 'nc-icon nc-money-coins',
        path: '/billing/wallet',
        modal: false
      },
      {
        show: rules.isAdmin || (!rules.isBroker && !rules.isSupport && rules.check('/billing/funding')),
        name: 'topuptitle',
        icon: 'nc-icon nc-refresh-69',
        path: '/billing/funding',
        modal: false
      },
      // {
      //   show: rules.isAdmin || (!rules.isBroker && rules.check('/billing/withdrawals')),
      //   name: 'withdrawals',
      //   icon: 'nc-icon nc-refresh-69',
      //   path: '/billing/withdrawals',
      //   modal: false
      // },
      {
        show: rules.isAdmin || (!rules.isBroker && !rules.isSupport && rules.check('/billing/orders')),
        name: 'wallet_order_title',
        icon: 'fa fa-usd',
        path: '/billing/orders',
        modal: false
      },
      {
        show: (rules.check('/billing/inout') && !rules.isAdmin && !rules.isSupport) || rules.isBroker,
        name: 'statementtxt',
        icon: 'fa fa-university',
        path: '/billing/inout',
        modal: false
      },
      {
        show: !rules.removeIfUserHas(['Neteller'])
          && ((rules.check('/billing/transfer') && !rules.isAdmin && !rules.isSupport) || rules.isBroker),
        name: 'billingtransfer',
        icon: 'fa fa-exchange',
        path: '/billing/transfer',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport || rules.check('/billing/marketplace'),
        name: 'payments',
        icon: 'fa fa-retweet',
        path: '/billing/marketplace',
        modal: false
      }
    ]
  },
  {
    show: !rules.isSupport,
    name: 'Partners',
    icon: 'fa fa-users',
    children: [
      {
        show: !rules.isAdmin && !rules.isBroker && rules.check('/affiliate'),
        name: 'affiliate_link',
        icon: 'fa fa-area-chart',
        path: '/affiliate/stats',
        modal: false
      },
      {
        show:  !rules.isWhitelabelSession || rules.routes.includes('/affiliate/banner'),
        name: 'banner',
        icon: 'fa fa-share',
        path: '/affiliate/banner',
        modal: false
      },
    ]
  },
  {
    show: true,
    roles: ['user','broker', 'admin'],
    name: 'Support',
    icon: 'nc-icon nc-support-17',
    children: [
      {
        show: !rules.isBroker && rules.check('/support/main'),
        roles: ['user', 'admin', 'support'],
        name: 'FAQ',
        icon: 'fa fa-question',
        path: '/support/main',
        modal: false
      },
      {
        show: !rules.isWhitelabelSession || (rules.isWhitelabelSession && (rules.check('/support/explanation'))),
        roles: ['user', 'broker', 'admin', 'support'],
        name: 'Pricing',
        icon: 'fa fa-usd',
        path: '/support/explanation',
        modal: false
      },
      {
        show: true,
        roles: ['user', 'broker', 'admin', 'support'],
        name: 'Need Help',
        icon: 'fa fa-ticket',
        path: '/support/helpdesk',
        modal: false
      },
      {
        show: true,
        roles: ['user', 'broker', 'admin', 'support'],
        name: 'Policies',
        icon: 'fa fa-book',
        path: '/support/policies',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isMarketing,
        roles: ['admin'],
        name: 'Messenger',
        icon: 'fa fa-envelope',
        path: '/support/messenger',
        modal: false
      },
      {
        show: rules.isAdmin,
        roles: ['admin'],
        name: 'servertxt',
        icon: 'fa fa-server',
        path: '/support/servers',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'userstext',
        icon: 'fa fa-users',
        path: '/users/manager',
        modal: false
      },
      {
        show: rules.isAdmin || rules.isSupport,
        roles: ['admin', 'support'],
        name: 'tickettxt',
        icon: 'fa fa-server',
        path: '/support/tickets',
        modal: false
      },
    ]
  },
];

/**
 * Method defined to filter links
 */
const filterLinks = link => {
  if (link.children) {
    link.children = link.children.filter(child => child.show);
    if (!link.children.length) {
      return false;
    }
  }
  return link.show;
};

export default sideMenuItems.filter(filterLinks)
