import SecureStore from 'src/plugins/secure-storage.js'
import jwt_decode from "jwt-decode";
import VueCookies from "vue-cookies";

const AUTH0URL = "https://hokocloud.eu.auth0.com";
const AUTH0DOMAIN = process.env.VUE_APP_AUTH0DOMAIN;
const AUTH0AUD = "hokocloud_dash";
const AUTH0CLIENTID = "kfMEOaCP5L5dGJL4hTuVtl8c-n1DVwkQ";
const AUTH0CONNECTION = "HokoCloud-Dashboard"
const AUTH0CALLBACKURI = "https://dash.hokocloud.com/authcallback"

const PLAYSTORE_URI = "https://play.google.com/store/apps/details?id=com.hokocloud.app"

const DEBUG = process.env.VUE_APP_DEBUG === 'true';
SecureStore.init(DEBUG);

const MAINAPIURL = process.env.VUE_APP_MAINAPIURL;
const MAINAPIURLV4 = process.env.VUE_APP_MAINAPIURL_V4;
const WALLETBALANCEAPI = process.env.VUE_APP_WALLET_BALANCE_API;
const WALLETURL = process.env.VUE_APP_WALLETURL;
const ACCAPIURL = process.env.VUE_APP_ACCAPIURL;
const ACCAPIURL_V4 = process.env.VUE_APP_ACCAPIURL_V4;
const MT4APIURL = process.env.VUE_APP_MT4APIURL;
const MESSENGERURL = process.env.VUE_APP_MESSENGERURL;
const PLOTAPIURL = process.env.VUE_APP_PLOTAPIURL;
const TASKERURL = process.env.VUE_APP_TASKER;
const WLADMINAPIURL = process.env.VUE_APP_WLADMINAPIURL;
const SSOURL = process.env.VUE_APP_SSOURL;

const RDPAPIURL = process.env.VUE_APP_RDPAPIURL;
const FRAUDAPIURL = process.env.VUE_APP_FRAUDAPIURL;
const REGAPIURL = process.env.VUE_APP_REGAPIURL;
const PUBAPIURL = process.env.VUE_APP_PUBAPIURL;

const MATAPIURL = process.env.VUE_APP_MATAPIURL;

const SMURL = process.env.VUE_APP_SMURL;
const SMCID = process.env.VUE_APP_SMCID;

const CPURL = process.env.VUE_APP_CPURL;
const CPPURL = process.env.VUE_APP_CPPURL;
const CPSTK = process.env.VUE_APP_CPSTK;
const CPMTK = process.env.VUE_APP_CPMTK;

const APPNAME = "HokoCloud";

const IPGEOLOCATION = "https://api.ipgeolocation.io/ipgeo?apiKey=72e0dcec246d478b9c77f40f43e5d69f"

const WALLETAPP = true;

const COUNTRYLIST =  [
  {
    "name": "Åland Islands",
    "code": "AX"
  }, {
    "name": "Albania",
    "code": "AL"
  }, {
    "name": "Algeria",
    "code": "DZ"
  }, {
    "name": "American Samoa",
    "code": "AS"
  }, {
    "name": "AndorrA",
    "code": "AD"
  }, {
    "name": "Angola",
    "code": "AO"
  }, {
    "name": "Anguilla",
    "code": "AI"
  }, {
    "name": "Antarctica",
    "code": "AQ"
  }, {
    "name": "Antigua and Barbuda",
    "code": "AG"
  }, {
    "name": "Argentina",
    "code": "AR"
  }, {
    "name": "Armenia",
    "code": "AM"
  }, {
    "name": "Aruba",
    "code": "AW"
  }, {
    "name": "Australia",
    "code": "AU"
  }, {
    "name": "Austria",
    "code": "AT"
  }, {
    "name": "Azerbaijan",
    "code": "AZ"
  }, {
    "name": "Bahamas",
    "code": "BS"
  }, {
    "name": "Bahrain",
    "code": "BH"
  }, {
    "name": "Bangladesh",
    "code": "BD"
  }, {
    "name": "Barbados",
    "code": "BB"
  }, {
    "name": "Belarus",
    "code": "BY"
  }, {
    "name": "Belgium",
    "code": "BE"
  }, {
    "name": "Belize",
    "code": "BZ"
  }, {
    "name": "Benin",
    "code": "BJ"
  }, {
    "name": "Bermuda",
    "code": "BM"
  }, {
    "name": "Bhutan",
    "code": "BT"
  }, {
    "name": "Bolivia",
    "code": "BO"
  }, {
    "name": "Bosnia and Herzegovina",
    "code": "BA"
  }, {
    "name": "Botswana",
    "code": "BW"
  }, {
    "name": "Bouvet Island",
    "code": "BV"
  }, {
    "name": "Brazil",
    "code": "BR"
  }, {
    "name": "British Indian Ocean Territory",
    "code": "IO"
  }, {
    "name": "Brunei Darussalam",
    "code": "BN"
  }, {
    "name": "Bulgaria",
    "code": "BG"
  }, {
    "name": "Burkina Faso",
    "code": "BF"
  }, {
    "name": "Burundi",
    "code": "BI"
  }, {
    "name": "Cambodia",
    "code": "KH"
  }, {
    "name": "Cameroon",
    "code": "CM"
  }, {
    "name": "Canada",
    "code": "CA"
  }, {
    "name": "Cape Verde",
    "code": "CV"
  }, {
    "name": "Cayman Islands",
    "code": "KY"
  }, {
    "name": "Central African Republic",
    "code": "CF"
  }, {
    "name": "Chad",
    "code": "TD"
  }, {
    "name": "Chile",
    "code": "CL"
  }, {
    "name": "China",
    "code": "CN"
  }, {
    "name": "Christmas Island",
    "code": "CX"
  }, {
    "name": "Cocos (Keeling) Islands",
    "code": "CC"
  }, {
    "name": "Colombia",
    "code": "CO"
  }, {
    "name": "Comoros",
    "code": "KM"
  }, {
    "name": "Congo",
    "code": "CG"
  }, {
    "name": "Congo, The Democratic Republic of the",
    "code": "CD"
  }, {
    "name": "Cook Islands",
    "code": "CK"
  }, {
    "name": "Costa Rica",
    "code": "CR"
  }, {
    "name": "Cote D'Ivoire",
    "code": "CI"
  }, {
    "name": "Croatia",
    "code": "HR"
  }, {
    "name": "Cuba",
    "code": "CU"
  }, {
    "name": "Cyprus",
    "code": "CY"
  }, {
    "name": "Czech Republic",
    "code": "CZ"
  }, {
    "name": "Denmark",
    "code": "DK"
  }, {
    "name": "Djibouti",
    "code": "DJ"
  }, {
    "name": "Dominica",
    "code": "DM"
  }, {
    "name": "Dominican Republic",
    "code": "DO"
  }, {
    "name": "Ecuador",
    "code": "EC"
  }, {
    "name": "Egypt",
    "code": "EG"
  }, {
    "name": "El Salvador",
    "code": "SV"
  }, {
    "name": "Equatorial Guinea",
    "code": "GQ"
  }, {
    "name": "Eritrea",
    "code": "ER"
  }, {
    "name": "Estonia",
    "code": "EE"
  }, {
    "name": "Ethiopia",
    "code": "ET"
  }, {
    "name": "Falkland Islands (Malvinas)",
    "code": "FK"
  }, {
    "name": "Faroe Islands",
    "code": "FO"
  }, {
    "name": "Fiji",
    "code": "FJ"
  }, {
    "name": "Finland",
    "code": "FI"
  }, {
    "name": "France",
    "code": "FR"
  }, {
    "name": "French Guiana",
    "code": "GF"
  }, {
    "name": "French Polynesia",
    "code": "PF"
  }, {
    "name": "French Southern Territories",
    "code": "TF"
  }, {
    "name": "Gabon",
    "code": "GA"
  }, {
    "name": "Gambia",
    "code": "GM"
  }, {
    "name": "Georgia",
    "code": "GE"
  }, {
    "name": "Germany",
    "code": "DE"
  }, {
    "name": "Ghana",
    "code": "GH"
  }, {
    "name": "Gibraltar",
    "code": "GI"
  }, {
    "name": "Greece",
    "code": "GR"
  }, {
    "name": "Greenland",
    "code": "GL"
  }, {
    "name": "Grenada",
    "code": "GD"
  }, {
    "name": "Guadeloupe",
    "code": "GP"
  }, {
    "name": "Guam",
    "code": "GU"
  }, {
    "name": "Guatemala",
    "code": "GT"
  }, {
    "name": "Guernsey",
    "code": "GG"
  }, {
    "name": "Guinea",
    "code": "GN"
  }, {
    "name": "Guinea-Bissau",
    "code": "GW"
  }, {
    "name": "Guyana",
    "code": "GY"
  }, {
    "name": "Haiti",
    "code": "HT"
  }, {
    "name": "Heard Island and Mcdonald Islands",
    "code": "HM"
  }, {
    "name": "Holy See (Vatican City State)",
    "code": "VA"
  }, {
    "name": "Honduras",
    "code": "HN"
  }, {
    "name": "Hong Kong",
    "code": "HK"
  }, {
    "name": "Hungary",
    "code": "HU"
  }, {
    "name": "Iceland",
    "code": "IS"
  }, {
    "name": "India",
    "code": "IN"
  }, {
    "name": "Indonesia",
    "code": "ID"
  }, {
    "name": "Iran",
    "code": "IR"
  },{
    "name": "Iraq",
    "code": "IQ"
  }, {
    "name": "Ireland",
    "code": "IE",
  }, {
    "name": "Isle of Man",
    "code": "IM"
  }, {
    "name": "Israel",
    "code": "IL"
  }, {
    "name": "Italy",
    "code": "IT"
  }, {
    "name": "Jamaica",
    "code": "JM"
  }, {
    "name": "Japan",
    "code": "JP"
  }, {
    "name": "Jersey",
    "code": "JE"
  }, {
    "name": "Jordan",
    "code": "JO"
  }, {
    "name": "Kazakhstan",
    "code": "KZ"
  }, {
    "name": "Kenya",
    "code": "KE"
  }, {
    "name": "Kiribati",
    "code": "KI"
  }, {
    "name": "Korea, Democratic People's Republic of",
    "code": "KP"
  }, {
    "name": "Korea, Republic of",
    "code": "KR"
  }, {
    "name": "Kuwait",
    "code": "KW"
  }, {
    "name": "Kyrgyzstan",
    "code": "KG"
  }, {
    "name": "Lao People's Democratic Republic",
    "code": "LA"
  }, {
    "name": "Latvia",
    "code": "LV"
  }, {
    "name": "Lebanon",
    "code": "LB"
  }, {
    "name": "Lesotho",
    "code": "LS"
  }, {
    "name": "Liberia",
    "code": "LR"
  }, {
    "name": "Libyan Arab Jamahiriya",
    "code": "LY"
  }, {
    "name": "Liechtenstein",
    "code": "LI"
  }, {
    "name": "Lithuania",
    "code": "LT"
  }, {
    "name": "Luxembourg",
    "code": "LU"
  }, {
    "name": "Macao",
    "code": "MO"
  }, {
    "name": "Macedonia, The Former Yugoslav Republic of",
    "code": "MK"
  }, {
    "name": "Madagascar",
    "code": "MG"
  }, {
    "name": "Malawi",
    "code": "MW"
  }, {
    "name": "Malaysia",
    "code": "MY"
  }, {
    "name": "Maldives",
    "code": "MV"
  }, {
    "name": "Mali",
    "code": "ML"
  }, {
    "name": "Malta",
    "code": "MT"
  }, {
    "name": "Marshall Islands",
    "code": "MH"
  }, {
    "name": "Martinique",
    "code": "MQ"
  }, {
    "name": "Mauritania",
    "code": "MR"
  }, {
    "name": "Mauritius",
    "code": "MU"
  }, {
    "name": "Mayotte",
    "code": "YT"
  }, {
    "name": "Mexico",
    "code": "MX"
  }, {
    "name": "Micronesia, Federated States of",
    "code": "FM"
  }, {
    "name": "Moldova, Republic of",
    "code": "MD"
  }, {
    "name": "Monaco",
    "code": "MC"
  }, {
    "name": "Mongolia",
    "code": "MN"
  }, {
    "name": "Montserrat",
    "code": "MS"
  }, {
    "name": "Morocco",
    "code": "MA"
  }, {
    "name": "Mozambique",
    "code": "MZ"
  }, {
    "name": "Myanmar",
    "code": "MM"
  }, {
    "name": "Namibia",
    "code": "NA"
  }, {
    "name": "Nauru",
    "code": "NR"
  }, {
    "name": "Nepal",
    "code": "NP"
  }, {
    "name": "Netherlands",
    "code": "NL"
  }, {
    "name": "Netherlands Antilles",
    "code": "AN"
  }, {
    "name": "New Caledonia",
    "code": "NC"
  }, {
    "name": "New Zealand",
    "code": "NZ"
  }, {
    "name": "Nicaragua",
    "code": "NI"
  }, {
    "name": "Niger",
    "code": "NE"
  }, {
    "name": "Nigeria",
    "code": "NG"
  }, {
    "name": "Niue",
    "code": "NU"
  }, {
    "name": "Norfolk Island",
    "code": "NF"
  }, {
    "name": "Northern Mariana Islands",
    "code": "MP"
  }, {
    "name": "Norway",
    "code": "NO"
  }, {
    "name": "Oman",
    "code": "OM"
  }, {
    "name": "Palau",
    "code": "PW"
  },
  {
    "name": "Pakistan",
    "code": "PK"
  },
  {
    "name": "Palestine",
    "code": "PS"
  }, {
    "name": "Panama",
    "code": "PA"
  }, {
    "name": "Papua New Guinea",
    "code": "PG"
  }, {
    "name": "Paraguay",
    "code": "PY"
  }, {
    "name": "Peru",
    "code": "PE"
  }, {
    "name": "Philippines",
    "code": "PH"
  }, {
    "name": "Pitcairn",
    "code": "PN"
  }, {
    "name": "Poland",
    "code": "PL"
  }, {
    "name": "Portugal",
    "code": "PT"
  }, {
    "name": "Puerto Rico",
    "code": "PR"
  }, {
    "name": "Qatar",
    "code": "QA"
  }, {
    "name": "Reunion",
    "code": "RE"
  }, {
    "name": "Romania",
    "code": "RO"
  }, {
    "name": "Russian Federation",
    "code": "RU"
  }, {
    "name": "RWANDA",
    "code": "RW"
  }, {
    "name": "Saint Helena",
    "code": "SH"
  }, {
    "name": "Saint Kitts and Nevis",
    "code": "KN"
  }, {
    "name": "Saint Lucia",
    "code": "LC"
  }, {
    "name": "Saint Pierre and Miquelon",
    "code": "PM"
  }, {
    "name": "Saint Vincent and the Grenadines",
    "code": "VC"
  }, {
    "name": "Samoa",
    "code": "WS"
  }, {
    "name": "San Marino",
    "code": "SM"
  }, {
    "name": "Sao Tome and Principe",
    "code": "ST"
  }, {
    "name": "Saudi Arabia",
    "code": "SA"
  }, {
    "name": "Senegal",
    "code": "SN"
  }, {
    "name": "Serbia and Montenegro",
    "code": "CS"
  }, {
    "name": "Seychelles",
    "code": "SC"
  }, {
    "name": "Sierra Leone",
    "code": "SL"
  }, {
    "name": "Singapore",
    "code": "SG"
  }, {
    "name": "Slovakia",
    "code": "SK"
  }, {
    "name": "Slovenia",
    "code": "SI"
  }, {
    "name": "Solomon Islands",
    "code": "SB"
  }, {
    "name": "Somalia",
    "code": "SO"
  }, {
    "name": "South Africa",
    "code": "ZA"
  }, {
    "name": "South Georgia and the South Sandwich Islands",
    "code": "GS"
  }, {
    "name": "Spain",
    "code": "ES"
  }, {
    "name": "Sri Lanka",
    "code": "LK"
  }, {
    "name": "Sudan",
    "code": "SD"
  }, {
    "name": "Suriname",
    "code": "SR"
  }, {
    "name": "Svalbard and Jan Mayen",
    "code": "SJ"
  }, {
    "name": "Swaziland",
    "code": "SZ"
  }, {
    "name": "Sweden",
    "code": "SE"
  }, {
    "name": "Switzerland",
    "code": "CH"
  }, {
    "name": "Taiwan, Province of China",
    "code": "TW"
  }, {
    "name": "Tajikistan",
    "code": "TJ"
  }, {
    "name": "Tanzania, United Republic of",
    "code": "TZ"
  }, {
    "name": "Thailand",
    "code": "TH"
  }, {
    "name": "Timor-Leste",
    "code": "TL"
  }, {
    "name": "Togo",
    "code": "TG"
  }, {
    "name": "Tokelau",
    "code": "TK"
  }, {
    "name": "Tonga",
    "code": "TO"
  }, {
    "name": "Trinidad and Tobago",
    "code": "TT"
  }, {
    "name": "Tunisia",
    "code": "TN"
  }, {
    "name": "Turkey",
    "code": "TR"
  }, {
    "name": "Turkmenistan",
    "code": "TM"
  }, {
    "name": "Turks and Caicos Islands",
    "code": "TC"
  }, {
    "name": "Tuvalu",
    "code": "TV"
  }, {
    "name": "Uganda",
    "code": "UG"
  }, {
    "name": "Ukraine",
    "code": "UA"
  }, {
    "name": "United Arab Emirates",
    "code": "AE"
  }, {
    "name": "United Kingdom",
    "code": "GB"
  }, {
    "name": "United States",
    "code": "US"
  }, {
    "name": "United States Minor Outlying Islands",
    "code": "UM"
  }, {
    "name": "Uruguay",
    "code": "UY"
  }, {
    "name": "Uzbekistan",
    "code": "UZ"
  }, {
    "name": "Vanuatu",
    "code": "VU"
  }, {
    "name": "Venezuela",
    "code": "VE"
  }, {
    "name": "Viet Nam",
    "code": "VN"
  }, {
    "name": "Virgin Islands, British",
    "code": "VG"
  }, {
    "name": "Virgin Islands, U.S.",
    "code": "VI"
  }, {
    "name": "Wallis and Futuna",
    "code": "WF"
  }, {
    "name": "Western Sahara",
    "code": "EH"
  }, {
    "name": "Yemen",
    "code": "YE"
  }, {
    "name": "Zambia",
    "code": "ZM"
  }, {
    "name": "Zimbabwe",
    "code": "ZW"
  }
];

const COUNTRYPHLIST =   {
  "BD": "880",
  "BE": "32",
  "BF": "226",
  "BG": "359",
  "BA": "387",
  "BB": "1-246",
  "WF": "681",
  "BL": "590",
  "BM": "1-441",
  "BN": "673",
  "BO": "591",
  "BH": "973",
  "BI": "257",
  "BJ": "229",
  "BT": "975",
  "JM": "1-876",
  "BW": "267",
  "WS": "685",
  "BQ": "599",
  "BR": "55",
  "BS": "1-242",
  "JE": "44-1534",
  "BY": "375",
  "BZ": "501",
  "RU": "7",
  "RW": "250",
  "RS": "381",
  "TL": "670",
  "RE": "262",
  "TM": "993",
  "TJ": "992",
  "RO": "40",
  "TK": "690",
  "GW": "245",
  "GU": "1-671",
  "GT": "502",
  "GR": "30",
  "GQ": "240",
  "GP": "590",
  "JP": "81",
  "GY": "592",
  "GG": "44-1481",
  "GF": "594",
  "GE": "995",
  "GD": "1-473",
  "GB": "44",
  "GA": "241",
  "SV": "503",
  "GN": "224",
  "GM": "220",
  "GL": "299",
  "GI": "350",
  "GH": "233",
  "OM": "968",
  "TN": "216",
  "JO": "962",
  "HR": "385",
  "HT": "509",
  "HU": "36",
  "HK": "852",
  "HN": "504",
  "HM": " ",
  "VE": "58",
  "PR": "1-787 and 1-939",
  "PS": "970",
  "PW": "680",
  "PT": "351",
  "SJ": "47",
  "PY": "595",
  "IQ": "964",
  "PA": "507",
  "PF": "689",
  "PG": "675",
  "PE": "51",
  "PK": "92",
  "PH": "63",
  "PN": "870",
  "PL": "48",
  "PM": "508",
  "ZM": "260",
  "EH": "212",
  "EE": "372",
  "EG": "20",
  "ZA": "27",
  "EC": "593",
  "IT": "39",
  "VN": "84",
  "SB": "677",
  "ET": "251",
  "SO": "252",
  "ZW": "263",
  "SA": "966",
  "ES": "34",
  "ER": "291",
  "ME": "382",
  "MD": "373",
  "MG": "261",
  "MF": "590",
  "MA": "212",
  "MC": "377",
  "UZ": "998",
  "MM": "95",
  "ML": "223",
  "MO": "853",
  "MN": "976",
  "MH": "692",
  "MK": "389",
  "MU": "230",
  "MT": "356",
  "MW": "265",
  "MV": "960",
  "MQ": "596",
  "MP": "1-670",
  "MS": "1-664",
  "MR": "222",
  "IM": "44-1624",
  "UG": "256",
  "TZ": "255",
  "MY": "60",
  "MX": "52",
  "IL": "972",
  "FR": "33",
  "IO": "246",
  "SH": "290",
  "FI": "358",
  "FJ": "679",
  "FK": "500",
  "FM": "691",
  "FO": "298",
  "NI": "505",
  "NL": "31",
  "NO": "47",
  "NA": "264",
  "VU": "678",
  "NC": "687",
  "NE": "227",
  "NF": "672",
  "NG": "234",
  "NZ": "64",
  "NP": "977",
  "NR": "674",
  "NU": "683",
  "CK": "682",
  "CI": "225",
  "CH": "41",
  "CO": "57",
  "CN": "86",
  "CM": "237",
  "CL": "56",
  "CC": "61",
  "CA": "1",
  "CG": "242",
  "CF": "236",
  "CD": "243",
  "CZ": "420",
  "CY": "357",
  "CX": "61",
  "CR": "506",
  "CW": "599",
  "CV": "238",
  "CU": "53",
  "SZ": "268",
  "SY": "963",
  "SX": "599",
  "KG": "996",
  "KE": "254",
  "SS": "211",
  "SR": "597",
  "KI": "686",
  "KH": "855",
  "KN": "1-869",
  "KM": "269",
  "ST": "239",
  "SK": "421",
  "KR": "82",
  "SI": "386",
  "KP": "850",
  "KW": "965",
  "SN": "221",
  "SM": "378",
  "SL": "232",
  "SC": "248",
  "KZ": "7",
  "KY": "1-345",
  "SG": "65",
  "SE": "46",
  "SD": "249",
  "DO": "1-809 and 1-829",
  "DM": "1-767",
  "DJ": "253",
  "DK": "45",
  "VG": "1-284",
  "DE": "49",
  "YE": "967",
  "DZ": "213",
  "US": "1",
  "UY": "598",
  "YT": "262",
  "UM": "1",
  "LB": "961",
  "LC": "1-758",
  "LA": "856",
  "TV": "688",
  "TW": "886",
  "TT": "1-868",
  "TR": "90",
  "LK": "94",
  "LI": "423",
  "LV": "371",
  "TO": "676",
  "LT": "370",
  "LU": "352",
  "LR": "231",
  "LS": "266",
  "TH": "66",
  "TG": "228",
  "TD": "235",
  "TC": "1-649",
  "LY": "218",
  "VA": "379",
  "VC": "1-784",
  "AE": "971",
  "AD": "376",
  "AG": "1-268",
  "AF": "93",
  "AI": "1-264",
  "VI": "1-340",
  "IS": "354",
  "IR": "98",
  "AM": "374",
  "AL": "355",
  "AO": "244",
  "AS": "1-684",
  "AR": "54",
  "AU": "61",
  "AT": "43",
  "AW": "297",
  "IN": "91",
  "AX": "358-18",
  "AZ": "994",
  "IE": "353",
  "ID": "62",
  "UA": "380",
  "QA": "974",
  "MZ": "258"
}

export default {
  methods: {
    accapiurl() {
      return ACCAPIURL;
    },
    accapiurlV4() {
      return ACCAPIURL_V4;
    },
    appname() {
      return APPNAME;
    },
    taskerurl() {
      return TASKERURL;
    },
    auth0url() {
      return AUTH0URL;
    },
    auth0clientid() {
      return AUTH0CLIENTID;
    },
    auth0audience() {
      return AUTH0AUD;
    },
    auth0domain() {
      if (window.location.hostname == 'www.acca90.com.br') {
        return 'login.acca90.com.br';
      }
      return AUTH0DOMAIN;
    },
    auth0conn() {
      return AUTH0CONNECTION;
    },
    auth0callbackurl() {
      return AUTH0CALLBACKURI;
    },
    countries() {
      return COUNTRYLIST;
    },
    countryphones() {
      return COUNTRYPHLIST;
    },
    fraudapiurl() {
      return FRAUDAPIURL;
    },
    mainapiurl() {
      return MAINAPIURL;
    },
    mainapiurlV4() {
      return MAINAPIURLV4;
    },
    walletbalanceapi() {
      return WALLETBALANCEAPI;
    },
    walleturl() {
      return WALLETURL;
    },
    plotapiurl() {
      return PLOTAPIURL;
    },
    wladminapiurl() {
      return WLADMINAPIURL;
    },
    SSOURL() {
      return SSOURL;
    },
    mt4apiurl() {
      return MT4APIURL;
    },
    messengerurl() {
      return MESSENGERURL;
    },
    matapiurl() {
      return MATAPIURL;
    },
    ipgeolocationurl() {
      return IPGEOLOCATION;
    },
    nonceme(length) {
      const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_';
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    pubapiurl() {
      return PUBAPIURL;
    },
    rdpapiurl() {
      return RDPAPIURL;
    },
    regapiurl() {
      return REGAPIURL;
    },
    walletme() {
      return WALLETAPP;
    },
    smIntegrationConf() {
      return {
        url: SMURL,
        cid: SMCID,
      }
    },
    cpIntegrationConf() {
      return {
        cpurl: CPURL,
        cppurl: CPPURL,
        cpstk: CPSTK,
        cpmtk: CPMTK,
      }
    },
    debugMode() {
      return DEBUG;
    },
    playStoreURI() {
      return PLAYSTORE_URI;
    },
    getRules() {
      return new (function () {

        const access_token = VueCookies.get('dash-at');
        const id_token = VueCookies.get('dash-it');
        let isAdmin = false;
        let isBroker = false;
        let isWlAdmin = false;
        let isMarketing = false;
        let isSupport = false;

        if (access_token) {
          let permissions = jwt_decode(access_token).permissions;
          let userRoles = jwt_decode(id_token)['http://hokocloud/user_info'].roles;
          isAdmin = permissions.includes("read:all") && permissions.includes("write:all") && userRoles.includes('Admin');
          isBroker = permissions.includes("read:broker") && permissions.includes("write:broker");
          isWlAdmin = permissions.includes("read:whitelabel") && permissions.includes("write:whitelabel");
          isMarketing = permissions.includes("read:marketing") && permissions.includes("write:marketing");
          isSupport = permissions.includes("read:support") && permissions.includes("write:support") && userRoles.includes('Support');
        }

        const uinfo = localStorage.getItem('user-info');
        const settings = localStorage.getItem('wlSettings');
        const json = settings ? JSON.parse(settings) : null
        const roles = uinfo ? JSON.parse(uinfo).app_metadata.roles : [];
        const whitelabelMD = uinfo ? JSON.parse(uinfo).app_metadata.whitelabel : null;
        const broker = uinfo ? JSON.parse(uinfo).app_metadata.broker : null;
        const userid = uinfo ? JSON.parse(uinfo).email : null;
        const routes = settings ? JSON.parse(settings).routes : null;
        const whitelabel = json ? json.whitelabel : null;
        const fullName = json ? json.fullname : null;
        const provision = json ? json.provision_url : null;
        const prefix = json ? json.vps_prefix : null;
        const policies = json ? json.policies : null;
        const open_account = json ? json.open_account : null;
        const first_page = json ? json.first_page : 'default';
        const snapshot = json ? json.snapshot : null;
        const hide_info_broker = json ? json.hide_info_broker : false
        const isWhitelabelAdmin = isWlAdmin ? (!settings ? true : whitelabel === whitelabelMD) : false
        const hide_telegram_notification = json ? json.hide_telegram_notification : false;
        const hide_free_replication = json ? json.hide_free_replication : false;
        const auto_add_brokers = json ? json.auto_add_brokers : true;
        const hide_premium = json ? json.hide_premium : false;
        const wl_domain = json ? json.domain : '';
        const hide_need_help = json ? json.hide_need_help : false;
        const disclaimer = json ? json.disclaimer : false;

        if (whitelabel) {
          try {
            document.getElementById('favicon16').href = '/static/img/icons/' + whitelabel.toLowerCase() + '.ico'
          } catch (s) {
          }
          try {
            document.getElementById('favicon32').href = '/static/img/icons/' + whitelabel.toLowerCase() + '.ico'
          } catch (s) {
          }
          try {
            document.getElementById('favicon96').href = '/static/img/icons/' + whitelabel.toLowerCase() + '.ico'
          } catch (s) {
          }
          try {
            document.getElementsByTagName('title')[0].innerHTML = fullName;
          } catch (s) {
          }
        } else {
          try {
            document.getElementById('favicon96').href = '/static/img/icons/hokocloud.ico';
          } catch (s) {
          }
          try {
            document.getElementById('favicon32').href = '/static/img/icons/hokocloud.ico';
          } catch (s) {
          }
          try {
            document.getElementById('favicon16').href = '/static/img/icons/hokocloud.ico';
          } catch (s) {
          }
          try {
            document.getElementsByTagName('title')[0].innerHTML = 'HokoCloud Dashboard';
          } catch (s) {
          }
        }

        /**
         * Userid
         */
        this.userid = userid;
        /**
         * Inform if user is autenthicated
         */
        this.isAuthenticated = !!uinfo;
        /**
         * User roles;
         */
        this.roles = roles;
        /**
         * Flag to inform if the user is a broker
         */
        this.isBroker = isBroker;
        /**
         * Broker
         */
        this.broker = broker;
        /**
         * Flag to inform if the user is a hokocloud admin
         */
        this.isAdmin = isAdmin;
        /**
         * Is marketing team
         */
        this.isMarketing = isMarketing;
        /**
         * Is support team
         */
        this.isSupport = isSupport;
        /**
         * Flag to inform if the user is a whitelabel admin
         */
        this.isWhitelabelAdmin = isWhitelabelAdmin;
        /**
         * Flag to inform if the user is logged in a whitelabel session
         */
        this.isWhitelabelSession = !!settings;
        this.isWLSession = function() {
          return localStorage.getItem('wlSettings') === null;
        }
        /**
         * List of all routes that a regular user can see in a whitelabel session
         */
        this.routes = routes || [];
        /**
         * Indicates which whitelabel is running the session
         */
        this.whitelabel = whitelabel;
        /**
         * Whitelabel full name
         */
        this.whitelabelFullName = fullName;
        /**
         * Whitelabel provision URL
         */
        this.provision = provision;
        /**
         * Whitelabel provision URL
         */
        this.prefix = prefix;
        /**
         * Whitelabel policies
         */
        this.policies = policies;
        /**
         * First page
         */
        this.first_page = first_page;
        /**
         * Open account link
         */
        this.open_account = open_account;
        /**
         * VPS Snapshot
         */
        this.snapshot = snapshot;
        /**
         * Show broker information or not
         */
        this.hide_info_broker = hide_info_broker;
        /**
         * hide telegram notification
         */
        this.hide_telegram_notification = hide_telegram_notification
        /**
         * User's whitelabel
         */
        this.userWhitelabel = whitelabelMD;
        /**
         * Indicates if user has role whitelabe admin for any whitelabel
         */
        this.hasWlRole = isWlAdmin;
        /**
         * hide broker's info
         */
        this.hide_info_broker = hide_info_broker
        /**
         * hide make premium
         */
        this.hide_premium = hide_premium;
        /**
         * hide_free_replication
         */
        this.hide_free_replication = hide_free_replication
        /**
         * auto add brokers
         */
        this.auto_add_brokers = auto_add_brokers
        /**
         * Get WL domain
         */
        this.wl_domain = wl_domain
        /**
         * Hide need help
         */
        this.hide_need_help = hide_need_help
        /**
         * Wl disclaimer
         * @type {string}
         */
        this.disclaimer = disclaimer
        /**
         * Check if path is active for
         * @param path
         */
        this.check = path => {
          if (!this.isWhitelabelSession || this.isWhitelabelAdmin || this.isAdmin || this.isBroker) {
            return true;
          }
          return this.routes.includes(path);
        };
        /**
         * Remove if user has custom role
         */
        this.removeIfUserHas = custom_roles => {
          return this.roles.filter(e => custom_roles.includes(e)).length > 0;
        };
        /**
         * Allow if user has custom role
         */
        this.allowIfUserHas = custom_roles => {
          return this.roles.filter(e => custom_roles.includes(e)).length > 0;
        };
        /**
         * Provide the firt page URL for whitelabel users
         */
        this.getFirstPage = () => {
          if (this.first_page == 'default') {
            return '/dash/main'
          }
          if (this.first_page == '/vps/request') {
            return '/vps/main';
          }
          if (this.first_page == '/affiliate') {
            return '/affiliate/share';
          }
          return this.first_page;
        };
        this.getBaseUrl = () => {
          let domain = 'dash.hokocloud.com';
          if (this.isWhitelabelSession) {
            domain = this.wl_domain || domain;
          }
          return `https://${domain}`
        };
        this.getPublicUrl = (url) => {
          let link = [this.getBaseUrl()];
          link.push('strat');
          link.push(url);
          if (this.isWhitelabelSession && !this.wl_domain) {
            link.push(this.whitelabel);
          }
          return link.join('/');
        };
      })
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
      );
    },
  }
}
